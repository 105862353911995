import React, { useEffect } from 'react';
import { useThemeContext } from '../../contprovider/themeProvider';
import { useLangContext } from '../../contprovider/langProvider';
import { useOpenContext } from '../../contprovider/openProvider';
import "./Carousel.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 
{
    Navigation, 
    Pagination,
    Scrollbar, 
    A11y, 
    Autoplay, 
    EffectCoverflow, 
    Keyboard 
} from 'swiper/modules';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
//// language objects
import { currentSeason, legacyText } from '../../languages/Carousel';
////
import * as pics from '../collections/img_legacy/fall'
import { Typography, Divider } from '@mui/material';
import SeasonCard from './seasonCard/seasonCard';
//import legacy photos
import 
{ 
    sum1, 
    sum2, 
    sum12, 
    sum16
} 
from "./img_legacy/summer";
import 
{
    wint1,
    wint2,
    wint13,
    wint14
}
from "./img_legacy/winter";
import
{
    spr2,
    spr5,
    spr11,
    spr13
}
from "./img_legacy/spring";

/////

const imageArray = Object.values(pics);

function MySlider() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open } = useOpenContext();

    useEffect(() => {
        document.title = `Collections | Eska C.`;

    }, []);
    ////
    const boxVariant1 = {
        visible: { opacity: 1, transition: {staggerChildren: 1, duration: 1}, },
        hidden: { opacity: 0, }
    };
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        control.start("visible");
    })
    ////

    return (
            <>
            <motion.div
            className={`carousel-container ${open ? "carouse-container-blurred" : ""}`}
            initial="hidden"
            animate={control}
            ref={ref}
            variants={boxVariant1}
            >
                <Typography id="carousel-title" variant="h2">{currentSeason.fall[currentLang]} <span>2024</span></Typography>
                <section className="carousel-inner-container">
                   
                        <Swiper
                        id="swiper-container"
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow, Keyboard]}
                        spaceBetween={ 5 }
                        slidesPerView={ 1 }
                        slidesPerGroup={ 1 }
                        effect={ "coverflow" }
                        coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                        grabCursor={ true }
                        centeredSlides={ true }
                        loop={ true }
                        keyboard={{ enabled: true }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            disabledClass: 'disabled_swiper_button'
                        }}
                        autoplay={{ delay: 3000, pauseOnMouseEnter: true,}}
                        pagination={{ clickable: true, type: 'fraction'  }}
                        scrollbar={{ draggable: true, hide: true, enabled: false }}
                        //onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            1245: {
                                slidesPerView: 3,
                                scrollbar: {
                                    enabled: true,
                                    draggable: true,
                                    hide: true
                                }
                            },
                            
                            1024: {
                                slidesPerView: 2,
                                scrollbar: {
                                    enabled: true
                                }
                                
                                
                            },

                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                                scrollbar: {
                                    enabled: false
                                }
                                
                            },

                            435: {
                                slidesPerView: 2
                            }
                        }}
                        >
                                <div className="image-holder-container">
                                    {imageArray.map((e, index) => {
                                        return <SwiperSlide><img 
                                            alt={`Outfit number ${index}`}
                                            index={index}
                                            src={e}
                                            loading="lazy"
                                            /></SwiperSlide>
                                    })}
                                </div>
                        </Swiper>
                
                </section>
                <div
                className="title-holder"
                >
                    <Typography 
                    variant="h3"
                    className="legacy-text"
                    style={{
                        color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`,
                        
                    }}
                    >
                        {legacyText[currentLang]}
                    </Typography>
                </div>
                <SeasonCard 
                pics={[sum1, sum2, sum12, sum16]} 
                season={`${currentSeason.summer[currentLang]} 2024`}
                navigateTo={"summer"}
                />
                <Divider 
                id={`${isDarkTheme ? "divider-darktheme" : "divider-lighttheme"}`}
                />
                <SeasonCard 
                pics={[spr2, spr5, spr11, spr13]}
                season={`${currentSeason.spring[currentLang]} 2024`}
                navigateTo={"spring"}
                />
                <Divider 
                id={`${isDarkTheme ? "divider-darktheme" : "divider-lighttheme"}`}
                />
                <SeasonCard 
                pics={[wint1, wint2, wint14, wint13]}
                season={`${currentSeason.winter[currentLang]} 2023/24`}
                navigateTo={"winter"}
                />
                
            </motion.div>
            <style jsx>
                {`
                #carousel-title {
                    color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
                }
                `}
            </style>
            </>
    )
            
    
}


export default MySlider;